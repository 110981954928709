import {
  RESET_BLAST,
  SET_EMAIL_BLAST,
  SET_IS_LOADING_BLAST,
  SET_SMS_BLAST,
} from "../actions/blast";

const initialState = {
  smsblasts: [],
  emailBlasts: [],
  isLoading: false,
};

const blasts = (state = initialState, action) => {
  switch (action.type) {
    case SET_SMS_BLAST:
      return { ...state, smsblasts: action.payload };

    case SET_EMAIL_BLAST:
      return { ...state, emailBlasts: action.payload };

    case SET_IS_LOADING_BLAST:
      return { ...state, isLoading: action.payload };

    case RESET_BLAST:
      return initialState;

    default:
      return state;
  }
};

export default blasts;
