import React from 'react';
import KONVEYWhite from '../Assets/KONVEYWhite.png';

const elements = [1, 2, 3, 4, 5, 6];
const Skeleton = () => {
  return (
    <div className="w-full h-full flex flex-row">
      {/* side bar skeleton  */}
      <div className="fixed flex flex-col bg-black lg:w-[300px] h-full">
        <div className="flex bg-[#454545] w-[300px] h-[64px]">
          <div className="flex flex-row items-center mx-auto">
            <img
              src={KONVEYWhite}
              alt=""
              className="self-center"
              width={100}
              height={50}
            />
          </div>
        </div>
        <div className="flex h-[60px]  border-white border-b-[1px] mb-2">
          <p className="font-nunito font-semibold text-sm  text-white text-center m-auto bg-gray animate-pulse w-1/2 h-[10px] rounded-full"></p>
        </div>

        <div className="flex flex-col w-[300px] items-start self-center mx-auto overflow-y-auto itemsHeight px-4 py-2">
          {elements.map(() => {
            return (
              <div className="w-full">
                <div className="flex w-full rounded-sm px-4 py-2 flex-row gap-2 justify-start items-center mt-2">
                  <div className="w-[20px] h-[20px] rounded-full bg-gray animate-pulse"></div>

                  <p className="font-nunito font-bold text-white opacity-75 w-1/2 h-[10px] bg-gray animate-pulse rounded-full"></p>
                </div>
              </div>
            );
          })}
        </div>

        <div className=" flex border-white border-y-[1px] justify-center items-center w-[300px] p-4 h-[80px] bottom-[40px] bg-black">
          <div
            className="flex gap-2 w-full justify-start items-center pl-6"
            role="button"
          >
            <div className="w-[50px] h-[50px] rounded-full bg-gray animate-pulse"></div>

            <div className="w-1/5 flex flex-col gap-2">
              <p className="font-nunito font-bold text-base w-1/2 h-[10px] bg-gray animate-pulse rounded-full"></p>
              <h2 className="font-nunito text-xs h-[10px] bg-gray animate-pulse rounded-full"></h2>
            </div>
          </div>
        </div>

        <div className="flex  w-[300px] bottom-0 z-10 h-[40px] justify-center items-center ">
          <div className="flex w-[300px] mx-auto justify-start items-center gap-2 pl-6">
            <div className="w-[25px] h-[25px] rounded-full bg-gray animate-pulse"></div>
            <div className="w-full">
              <p className="font-nunito font-bold text-base bg-gray animate-pulse w-1/3 h-[10px] rounded-full text-white"></p>
            </div>
          </div>
        </div>
      </div>
      {/* end ofside bar skeleton  */}

      <>
        {/* topbar skeleton  */}
        <div className="top-0 z-10  bg-white flex flex-row justify-between items-center h-[64px] shadow-md topbarSkeleton font-nunito px-8">
          <div className="flex flex-row w-1/2">
            <h3 className=" font-bold text-lg bg-gray h-[10px] w-1/5 animate-pulse rounded-full"></h3>
          </div>

          <div className="flex items-center gap-4">
            <div className="h-[40px] w-[150px] rounded-md bg-gray animate-pulse"></div>
            <div className="w-[20px] h-[20px] bg-gray rounded-full animate-pulse"></div>
          </div>
        </div>
        {/* end of topbar skeleton  */}

        {/* home content skeleton  */}
        <div className="flex flex-col contentSkeleton py-8 px-32">
          <div className="relative flex flex-col items-center w-full h-[180px]">
            <div className="h-[180px] w-full bg-gray animate-pulse ">
              <div className="box-content absolute  bottom-[-50px] h-[100px] w-[100px] border-[5px] border-white rounded-full left-[100px] transform -translate-x-1/2 bg-gray animate-pulse"></div>
            </div>
          </div>
          <div className="w-1/2 ml-8 mt-16">
            <h2 className="font-[700]  w-1/3 bg-gray h-[10px] animate-pulse rounded-full"></h2>

            <h2 className="font-[700] mx-auto w-full bg-gray h-[10px] animate-pulse rounded-full mt-2"></h2>
          </div>

          <div className="flex gap-6 ml-8 mt-12">
            <div className="w-[80px] h-[20px] bg-gray animate-pulse rounded-full"></div>
            <div className="w-[80px] h-[20px] bg-gray animate-pulse rounded-full"></div>
            <div className="w-[80px] h-[20px] bg-gray animate-pulse rounded-full"></div>
          </div>

          <div className="flex items-center justify-center gap-4 mt-12">
            <div className="bg-gray animate-pulse w-1/4 h-[200px] rounded-md"></div>
            <div className="bg-gray animate-pulse w-1/4 h-[200px] rounded-md"></div>
            <div className="bg-gray animate-pulse w-1/4 h-[200px] rounded-md"></div>
          </div>
        </div>

        {/* end of home content skeleton  */}
      </>
    </div>
  );
};

export default Skeleton;
