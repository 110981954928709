import axios from "axios";
import { APP, config } from "../../utils/constant";

export const SET_QR_CODE = "SET_QR_CODE";

export const SET_IS_LOADING_QR_CODE = "SET_IS_LOADING_QR_CODE";

export const RESET_QR_CODE = "RESET_QR_CODE";

export const setQrCode = (code) => (dispatch) => {
  dispatch({
    type: SET_QR_CODE,
    payload: code,
  });
};

export const setIsLoadingQrCode = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_QR_CODE,
    payload: trueOrFalse,
  });
};

export const resetQrCode = () => ({ type: RESET_QR_CODE });

export const getQrCode = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  const getLatest = (a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  };

  dispatch(setIsLoadingQrCode(true));
  axios
    .get(
      APP.API_ADVERTISER_URL +
        `/advertiser/profile/${profileId}/custom-qr-codes`,
      config(token)
    )
    .then((res) => {
      dispatch(setQrCode(res.data.data.sort(getLatest)));
      dispatch(setIsLoadingQrCode(false));
    })
    .catch((error) => {
      console.log("error occured when fetching qr codes", error);
      dispatch(setIsLoadingQrCode(false));
      // error.response.status === 403 &&
      //   (window.location.href = 'https://account.konvey.rw/');
    });
};
