import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AudienceDrag from './components/AudienceDrag';

const tasks = [
  { id: '1', content: 'First task' },
  { id: '2', content: 'Second task' },
  { id: '3', content: 'Third task' },
  { id: '4', content: 'Fourth task' },
  { id: '5', content: 'Fifth task' },
];

const taskStatus = {
  requested: {
    name: 'Requested',
    items: tasks,
  },
  firstName: {
    name: 'First Name',
    items: [],
  },
  lastName: {
    name: 'Last Name',
    items: [],
  },
  phone: {
    name: 'Phone',
    items: [],
  },
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    let removedDest = null;
    if (
      result.destination.droppableId !== 'requested' &&
      destItems.length === 1
    ) {
      removedDest = destItems.splice(0, 1);
    }
    destItems.splice(0, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
      ...(removedDest && {
        requested: {
          ...columns.requested,
          items: [...sourceItems, ...removedDest],
        },
      }),
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

export const Test = () => {
  const [columns, setColumns] = useState(taskStatus);
  const [myTestFile, setMyTestFile] = useState([]);
  // const [columnB, setColumnB] = useState(test);
  console.log(Object.entries(columns)[0], 'these are the data');
  console.log(myTestFile, 'myFamousTestFile');
  return (
    <div className="h-full w-full overflow-y-scroll">
      <AudienceDrag
        style={'h-max'}
        label={'Upload Audience file'}
        files={myTestFile}
        setFiles={setMyTestFile}
      />
      <h1 className="text-center font-bold">Drag N Drop</h1>

      <div className="flex flex-col items-center">
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            className="bg-black text-white flex flex-col"
            key={Object.entries(columns)[0]}
          >
            <h2>{Object.entries(columns)[0][0]}</h2>
            <div className="flex flex-row m-8">
              <Droppable
                droppableId={Object.entries(columns)[0][0]}
                key={Object.entries(columns)[0][0]}
                direction="horizontal"
              >
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="flex flex-row"
                      style={{
                        background: snapshot.isDraggingOver
                          ? 'lightblue'
                          : 'lightgrey',
                        padding: 4,
                        minWidth: 250,
                        minHeight: 250,
                      }}
                    >
                      {Object.entries(columns)[0][1].items.map(
                        (item, index) => {
                          console.log(item);
                          return (
                            <Draggable
                              key={item.id.toString()}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: 'none',
                                      padding: 16,
                                      margin: '0 0 8px 0',
                                      minHeight: '20px',
                                      backgroundColor: snapshot.isDragging
                                        ? '#263B4A'
                                        : '#456C86',
                                      color: 'white',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    {item.content}
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        }
                      )}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            {Object.entries(columns).map(([columnId, column], index) => {
              if (column.name !== 'Requested') {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    key={columnId.toString()}
                  >
                    <h2>{column.name}</h2>
                    <div className="flex flex-row m-8">
                      <Droppable
                        droppableId={columnId.toString()}
                        key={columnId.toString()}
                        direction="horizontal"
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="flex flex-row"
                              style={{
                                background: snapshot.isDraggingOver
                                  ? 'lightblue'
                                  : 'lightgrey',
                                padding: 4,
                                minWidth: 250,
                                minHeight: 250,
                              }}
                            >
                              {column.items.map((item, index) => {
                                console.log(item);
                                return (
                                  <Draggable
                                    key={item.id.toString()}
                                    draggableId={item.id.toString()}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: 'none',
                                            padding: 16,
                                            margin: '0 0 8px 0',
                                            minHeight: '20px',
                                            backgroundColor: snapshot.isDragging
                                              ? '#263B4A'
                                              : '#456C86',
                                            color: 'white',
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          {item.content}
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};
