import {
  SET_USER_DETAILS,
  SET_USER_TOKEN,
  RESET_USER,
  PROFILE_TYPE,
  PROFILE_ID,
  SELECTED_DEFAULT_PROFILE,
} from '../actions/user';

const initialState = {
  userDetails: {},
  token: '',
  profileType: '',
  profileId: '',
  selectedDefaultProfile: {},
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return { ...state, userDetails: action.payload };
    case PROFILE_TYPE:
      return { ...state, profileType: action.payload };
    case PROFILE_ID:
      return { ...state, profileId: action.payload };

    case SET_USER_TOKEN:
      return { ...state, token: action.payload };

    case SELECTED_DEFAULT_PROFILE:
      return { ...state, selectedDefaultProfile: action.payload };

    case RESET_USER:
      return initialState;

    default:
      return state;
  }
};

export default user;
