import axios from 'axios';
import { APP, config } from '../../utils/constant';

export const SET_PROFILES = 'SET_PROFILES';
export const SET_PROFILE_CONTENT = 'SET_PROFILE_CONTENT';
export const SET_COMPANY_PROFILES = 'SET_COMPANY_PROFILES';

export const SET_IS_LOADING_PROFILES = 'SET_IS_LOADING_PROFILES';

export const RESET_PROFILES = 'RESET_PROFILES';

export const setProfiles = (profiles) => (dispatch) => {
  dispatch({
    type: SET_PROFILES,
    payload: profiles,
  });
};
export const setProfileContent = (content) => (dispatch) => {
  dispatch({
    type: SET_PROFILE_CONTENT,
    payload: content,
  });
};

export const setCompanyProfiles = (profiles) => (dispatch) => {
  dispatch({
    type: SET_COMPANY_PROFILES,
    payload: profiles,
  });
};

export const setIsLoadingProfiles = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_PROFILES,
    payload: trueOrFalse,
  });
};

export const resetProfiles = () => ({ type: RESET_PROFILES });

export const getUserProfile = () => (dispatch, getState) => {
  const { token, profileType, profileId } = getState().user;

  dispatch(setIsLoadingProfiles(true));

  if (profileType === 'company') {
    axios
      .get(APP.API_ADVERTISER_URL + `/advertiser/profile`, config(token))
      .then((res) => {
        dispatch(setProfiles(res.data.data));
        dispatch(setIsLoadingProfiles(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setIsLoadingProfiles(false));
        // error.response.status === 403 &&
        //   (window.location.href = 'https://account.konvey.rw/');
      });
  } else {
    axios
      .get(APP.API_ADVERTISER_URL + `/advertiser/profile`, config(token))
      .then((res) => {
        dispatch(setIsLoadingProfiles(false));
        dispatch(setProfiles(res.data.data));
        console.log('hello profiles', res.data.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(setIsLoadingProfiles(false));
        // error.response.status === 403 &&
        //   (window.location.href = 'https://account.konvey.rw/');
      });
  }
  axios
    .get(
      APP.API_ADVERTISER_URL + `/utils/user/profile-content-types`,
      config(token)
    )
    .then((res) => {
      dispatch(setProfileContent(res.data.data));
    })
    .catch((error) => console.log('error in ftching content typee', error));
};
