import { ToastComponent } from "../components/Toast";
export const APP = {
  API_MAIN_URL: process.env.REACT_APP_API_MAIN_URL,
  API_ADVERTISER_URL: process.env.REACT_APP_API_ADVERTISER,
  ACCOUNT_URL: process.env.REACT_APP_ACCOUNT_URL,
  ADMIN_BASE_URL: process.env.REACT_APP_ADMIN_BASE_URL,
  ADVERTISER_BASE_URL: process.env.REACT_APP_ADVERTISER_BASE_URL,
};

export const config = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
};

export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const notify = (type, message) => {
  ToastComponent({
    message: message,
    type: type,
  });
};

