import React from "react";
import { useEffect, useRef, useState } from "react";

import {
  generateVideoThumbnails,
  generateVideoThumbnailViaUrl,
  importFileandPreview,
} from "@rajesh896/video-thumbnails-generator";
import VideoThumbnail from "react-video-thumbnail";

function Thumb() {
  const [video, setVideo] = useState("");
  const [thumbNumber, setThumbNumber] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoThumb, setVideoThumb] = useState("");
  const [thumbnails, setThumbnails] = useState([]);
  const refs = useRef({
    video: null,
    loader: null,
    numberInput: null,
    thumbButton: null,
  });

  useEffect(() => {
    if (video) {
      importFileandPreview(video).then((res) => {
        setVideoUrl(res);
      });
      setVideoThumb("");
      setThumbnails([]);
      if (refs.current.video) {
        refs.current.video.style.transform = "scale(1)";
      }

      if (refs.current.numberInput) {
        refs.current.numberInput.style.display = "block";
      }
      if (refs.current.thumbButton) {
        refs.current.thumbButton.style.display = "block";
      }
    }
  }, [video]);
  // console.log("ddddd", video);
  const [res, setRes] = useState([]);

  useEffect(() => {
    generateVideoThumbnailViaUrl(video, 2)
      .then((thumbnailArray) => {
        setRes(thumbnailArray);
        console.log("res", res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <>
      <div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <video
            poster={videoThumb}
            style={{
              maxWidth: 600,
              maxHeight: 400,
              transform: "scale(0)",
              transition: "all 0.3s",
            }}
            controls
            id="video"
            ref={(el) => (refs.current.video = el)}
            src={videoUrl}
          >
            <source src={videoUrl} type={video?.type} />
            Your browser does not support the video tag.
          </video>
          <div style={{ display: "flex", marginTop: 25 }}>
            <input
              type="file"
              id="inputfile"
              accept="video/*"
              onChange={(e) => {
                if (e.target.files?.length > 0) {
                  setVideo(e.target.files[0]);
                }
              }}
            />
            <div
              id="numberWrapper"
              style={{ display: "none" }}
              ref={(el) => (refs.current.numberInput = el)}
            >
              <label for="numberofthumbnails" style={{ marginLeft: 15 }}>
                Enter number of thumbnails to generate
              </label>
              <input
                type="number"
                id="numberofthumbnails"
                onChange={(e) => {
                  setThumbNumber(parseInt(e.target.value, 0));
                }}
              />
            </div>
          </div>
          <div
            style={{ marginTop: 25, display: "none" }}
            id="buttonWrapper"
            ref={(el) => (refs.current.thumbButton = el)}
          >
            <button
              className="bg-red-400 px-2 rounded-md"
              id="generatethumbnails"
              onClick={() => {
                if (video) {
                  if (refs.current.loader) {
                    refs.current.loader.style.display = "block";
                  }

                  generateVideoThumbnails(video, thumbNumber).then((thumbs) => {
                    setThumbnails(thumbs);
                    if (refs.current.loader) {
                      refs.current.loader.style.display = "none";
                    }
                  });
                }
              }}
            >
              Generate Thumbnails
            </button>
          </div>
        </div>
        <div
          id="loader"
          style={{ display: "none", textAlign: "center" }}
          ref={(el) => (refs.current.loader = el)}
        >
          <img
            src="https://i.giphy.com/media/l3nWhI38IWDofyDrW/giphy.webp"
            alt=""
          />
        </div>
        <div
          id="thumbnails"
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
            transition: "all 0.3s",
          }}
        >
          {thumbnails.map((item) => {
            return (
              <img
                src={item}
                style={{ width: 200, margin: 10, cursor: "pointer" }}
                alt=""
                onClick={() => {
                  setVideoThumb(item);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="flex justify-center">
        <VideoThumbnail
          videoUrl="https://dl.dropboxusercontent.com/s/7b21gtvsvicavoh/statue-of-admiral-yi-no-audio.mp4?dl=1"
          thumbnailHandler={(thumbnail) => console.log(thumbnail)}
          width={120}
          height={80}
        /> */}

        <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div class="flex justify-end px-4 pt-4">
            <button
              id="dropdownButton"
              data-dropdown-toggle="dropdown"
              class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
              type="button"
            >
              <span class="sr-only">Open dropdown</span>
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 3"
              >
                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
              </svg>
            </button>
            {/* Dropdown menu */}
            <div
              id="dropdown"
              class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul class="py-2" aria-labelledby="dropdownButton">
                <li>
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Edit
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Export Data
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col items-center pb-10">
            <img
              class="w-24 h-24 mb-3 rounded-full shadow-lg"
              src="/docs/images/people/profile-picture-3.jpg"
              alt="Bonnie image"
            />
            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">
              Bonnie Green
            </h5>
            <span class="text-sm text-gray-500 dark:text-gray-400">
              Visual Designer
            </span>
            <div class="flex mt-4 space-x-3 md:mt-6">
              <a
                href="#"
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Add friend
              </a>
              <a
                href="#"
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
              >
                Message
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Thumb;
