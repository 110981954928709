import {
  SET_ADD_BLAST_EMAIL,
  SET_ADD_BLAST_SMS,
  RESET_MODAL,
  IS_FETCHING_BANKS,
  FETCH_BANKS,
  IS_FETCHING_COUNTRIES,
  FETCH_COUNTRIES,
  SET_OPEN_TOP_UP,
  SET_OPEN_IMPORT_AUDIENCE_MODAL,
  SET_CLOSE_IMPORT_AUDIENCE_MODAL,
  SET_AUDIENCE_ID,
  SET_AUDIENCE_STEP_TWO,
  SET_REMOVE_AUDIENCE_STEP_TWO,
} from '../actions/utils';

const initialState = {
  smsMode: false,
  emailMode: false,
  topUpMode: false,
  importAudienceModal: false,
  audienceModal: {
    id: '',
    addMemberMode: false,
  },
  is_fetching_banks: false,
  is_fetching_countries: false,
  fetched_banks: [],
  fetched_countries: [],
};

export const fetchUtils = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_BANKS:
      return {
        ...state,
        is_fetching_banks: action.payload,
      };

    case IS_FETCHING_COUNTRIES:
      return {
        ...state,
        is_fetching_countries: action.payload,
      };

    case FETCH_BANKS:
      return {
        ...state,
        fetched_banks: action.payload,
      };

    case FETCH_COUNTRIES:
      return {
        ...state,
        fetched_countries: action.payload,
      };

    case SET_ADD_BLAST_EMAIL:
      return { ...state, emailMode: action.payload };

    case SET_ADD_BLAST_SMS:
      return { ...state, smsMode: action.payload };

    case SET_OPEN_TOP_UP:
      return { ...state, topUpMode: action.payload };

    case SET_OPEN_IMPORT_AUDIENCE_MODAL:
      return { ...state, importAudienceModal: action.payload };

    case SET_CLOSE_IMPORT_AUDIENCE_MODAL:
      return { ...state, importAudienceModal: action.payload };

    case SET_AUDIENCE_ID:
      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          id: action.payload,
        },
      };

    case SET_AUDIENCE_STEP_TWO:
      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          addMemberMode: action.payload,
        },
      };

    case SET_REMOVE_AUDIENCE_STEP_TWO:
      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          addMemberMode: action.payload,
        },
      };

    default:
      return state;
  }
};
