import {
  RESET_PROFILES,
  SET_PROFILES,
  SET_COMPANY_PROFILES,
  SET_IS_LOADING_PROFILES,
  SET_PROFILE_CONTENT,
} from "../actions/profile";

const initialState = {
  companyProfiles: [],
  individualProfiles: [],
  profileContentTypes: [],
  isLoading: false,
};

const profiles = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILES:
      return { ...state, individualProfiles: action.payload };
    case SET_PROFILE_CONTENT:
      return { ...state, profileContentTypes: action.payload };
    case SET_COMPANY_PROFILES:
      return { ...state, companyProfiles: action.payload };
    case SET_IS_LOADING_PROFILES:
      return { ...state, isLoading: action.payload };

    case RESET_PROFILES:
      return initialState;

    default:
      return state;
  }
};

export default profiles;
