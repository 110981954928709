import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';

const Drag = ({
  style,
  flexStyle,
  uploadLimit,
  label,
  files,
  setFiles,
  file,
}) => {
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    margin: '0 4px 4px 0',
  };

  const thumbInner = {
    display: 'flex',
    minWidth: '100%',
    overflow: 'hidden',
  };

  const img = {
    display: 'hidden',
    width: '100%',
    height: '100%',
  };

  const btn = {
    width: '30px',
    height: '30px',
    padding: '12px 8px',
  };

  const [disabled, setDisabled] = useState(false);
  const [imageName, setImageName] = useState([]);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          setFiles((files) => [...files, base64String]);
        };
        reader.readAsDataURL(file);
        if (files.length === uploadLimit) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      });
      imageName.push(...acceptedFiles);
      setImageName(imageName);
    },
    maxFiles: 1,
    disabled: disabled,
  });

  const handleDelete = (file, index) => {
    //console.log(files,'file in delete')
    URL.revokeObjectURL(file.preview);
    setImageName((imageName) => imageName.splice(index, 1));
    files.splice(index, 1);
    setFiles(files);
    //setImageName(imageName);
  };

  const thumbs = (file, index) => {
    console.log(file,index, 'file in drag');
    return (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          {file?.type.startsWith('image/') ? (
            <img
              src={URL.createObjectURL(file)}
              style={img}
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
              }}
            />
          ) : (
            <span>{file.name}</span>
          )}
        </div>
        <button
          type="button"
          style={btn}
          onClick={() => handleDelete(file, index)}
        >
          <AiFillDelete size={20} className="" />
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (files.length === uploadLimit) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [imageName, files, handleDelete]);

  return (
    <div className={`${flexStyle} font-nunito w-full`}>
      <aside className="w-full">
        <h4 className="text-[#aeadad]">
          {imageName.length !== 0 ? 'Files' : 'No files uploaded'}
        </h4>
        <ul className="w-full flex flex-col space-y-2">
          {imageName.map((file, index) => (
            <li
              key={file.name}
              className="flex items-center justify-between bg-secondary  shadow-lg px-2 py-2 w-full flex-row space-between rounded-sm"
            >
              <div className="flex items-center space-x-2">
                <span>{file.name}</span>
              </div>
              <aside style={thumbsContainer}>{thumbs(file, index)}</aside>
            </li>
          ))}
        </ul>
      </aside>
      <div className="w-full h-fit flex flex-col">
        <label className="mb-1">{label}</label>
        <div
          className={`bg-white ${style} border border-dotted flex flex-col p-[16px] items-center justify-center rounded-lg w-full`}
        >
          <div
            {...getRootProps({
              className:
                'dropzone relative mb-[25px] flex flex-col space-y-2 items-center justify-center w-full',
            })}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              onClick={open}
              className="flex items-center justify-center top-0 left-0  w-full z-1 h-full  border-none outline-none rounded-[4px]"
            >
              <i className="">
                <FiUpload size={20} />
              </i>
            </button>
            <div className="">
              <h1>
                Drag and drop or
                <button type="button" onClick={open} className="font-bold mx-2">
                  Choose file
                </button>
                to upload.{' '}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drag;
