import axios from 'axios';
import { APP } from '../../utils/constant';
export const IS_FETCHING_MOBILE_ACCOUNT = 'CONST IS_FETCHING_MOBILE_ACCOUNT';
export const IS_FETCHING_BANK_ACCOUNT = 'CONST IS_FETCHING_BANK_ACCOUNT';
export const FETCH_MOBILE_ACCOUNT = 'FETCH_MOBILE_ACCOUNT';
export const FETCH_ALL_MOBILE_ACCOUNT = 'FETCH_ALL_MOBILE_ACCOUNT';
export const FETCH_ALL_BANK_ACCOUNT = 'FETCH_ALL_BANK_ACCOUNT';
export const FETCH_BANK_ACCOUNT = 'FETCH_BANK_ACCOUNT';
export const FETCH_DEFAULT_ACCOUNT = 'FETCH_DEFAULT_ACCOUNT';
export const FETCH_DEFAULT_ACCOUNT_TYPE = 'FETCH_DEFAULT_ACCOUNT_TYPE';
export const IS_FETCHING_WALLETS = 'IS_FETCHING_WALLETS';
export const FETCH_CREDIT_WALLET = 'FETCH_CREDIT_WALLET';
export const FETCH_PAYMENT_WALLET = 'FETCH_PAYMENT_WALLET';
export const IS_FETCHING_CREDIT_DEPOSITS = 'IS_FETCHING_CREDIT_DEPOSITS';
export const FETCH_CREDIT_DEPOSITS = 'FETCH_CREDIT_DEPOSITS';

export const IS_FETCHING_CREDIT_TRANSACTIONS =
  'IS_FETCHING_CREDIT_TRANSACTIONS';
export const FETCH_CREDIT_TRANSACTIONS = 'FETCH_CREDIT_TRANSACTIONS';
export const IS_FETCHING_CREDIT_TRANSFERS = 'IS_FETCHING_CREDIT_TRANSFERS';
export const FETCH_CREDIT_TRANSFERS = 'FETCH_CREDIT_TRANSFERS';

export const IS_FETCHING_PAYMENT_DEPOSITS = 'IS_FETCHING_PAYMENT_DEPOSITS';
export const FETCH_PAYMENT_DEPOSITS = 'FETCH_PAYMENT_DEPOSITS';

export const IS_FETCHING_PAYMENT_TRANSACTIONS =
  'IS_FETCHING_PAYMENT_TRANSACTIONS';
export const FETCH_PAYMENT_TRANSACTIONS = 'FETCH_PAYMENT_TRANSACTIONS';
export const IS_FETCHING_PAYMENT_TRANSFERS = 'IS_FETCHING_PAYMENT_TRANSFERS';
export const FETCH_PAYMENT_TRANSFERS = 'FETCH_PAYMENT_TRANSFERS';
export const FETCH_CREDIT_USAGE = 'FETCH_CREDIT_USAGE';
export const FETCH_PAYMENT_USAGE = 'FETCH_PAYMENT_USAGE';

export const IS_FETCHING_BANK_WITHDRAWS = 'IS_FETCHING_BANK_WITHDRAWS';
export const FETCH_BANK_WITHDRAWS = 'FETCH_BANK_WITHDRAWS';

export const IS_FETCHING_MOBILE_WITHDRAWS = 'IS_FETCHING_MOBILE_WITHDRAWS';
export const FETCH_MOBILE_WITHDRAWS = 'FETCH_MOBILE_WITHDRAWS';

const accountTypes = {
  mobile: 'mobile',
  bank: 'bank',
};

export const setIsFetchingMobileAccount = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_MOBILE_ACCOUNT,
    payload: payload,
  });
};

export const setIsFetchingBankAccount = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_BANK_ACCOUNT,
    payload: payload,
  });
};

export const setAllMobileAccount = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_ALL_MOBILE_ACCOUNT,
    payload,
  });
};

export const setAllBankAccount = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_ALL_BANK_ACCOUNT,
    payload,
  });
};

export const setMobileAccount = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_MOBILE_ACCOUNT,
    payload,
  });
};

export const setBankAccount = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_BANK_ACCOUNT,
    payload,
  });
};

export const setDefaultAccount = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_DEFAULT_ACCOUNT,
    payload,
  });
};

export const setDefaultAccountType = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_DEFAULT_ACCOUNT_TYPE,
    payload,
  });
};

export const setIsFetchingWallets = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_WALLETS,
    payload: payload,
  });
};

export const setIsFetchingMobileWithdraws = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_MOBILE_WITHDRAWS,
    payload: payload,
  });
};

export const setIsFetchingBankWithdraws = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_BANK_WITHDRAWS,
    payload: payload,
  });
};

export const setCreditWallet = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_WALLET,
    payload,
  });
};

export const setPaymentWallet = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_PAYMENT_WALLET,
    payload,
  });
};

export const setIsFetchingCreditDeposits = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_CREDIT_DEPOSITS,
    payload,
  });
};

export const setCreditDeposits = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_DEPOSITS,
    payload,
  });
};

export const setIsFetchingCreditTransactions = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_CREDIT_TRANSACTIONS,
    payload,
  });
};

export const setCreditTransactions = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_TRANSACTIONS,
    payload,
  });
};

export const setIsFetchingCreditTransfers = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_CREDIT_TRANSFERS,
    payload,
  });
};

export const setCreditTransfers = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_TRANSFERS,
    payload,
  });
};
export const setCreditUsage = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_USAGE,
    payload,
  });
};

export const setIsFetchingPaymentDeposits = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_PAYMENT_DEPOSITS,
    payload,
  });
};

export const setPaymentDeposits = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_PAYMENT_DEPOSITS,
    payload,
  });
};

export const setIsFetchingPaymentTransactions = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_PAYMENT_TRANSACTIONS,
    payload,
  });
};

export const setPaymentTransactions = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_PAYMENT_TRANSACTIONS,
    payload,
  });
};

export const setIsFetchingPaymentTransfers = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_PAYMENT_TRANSFERS,
    payload,
  });
};

export const setPaymentTransfers = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_PAYMENT_TRANSFERS,
    payload,
  });
};

export const setMobileWithdraws = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_MOBILE_WITHDRAWS,
    payload,
  });
};

export const setBankWithdraws = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_BANK_WITHDRAWS,
    payload,
  });
};

export const fetchMobileWithdraws = () => (dispatch, getState) => {
  const { token } = getState().user;
  const { payment_wallet } = getState().fetchWallet;
  if (payment_wallet.length > 0) {
    dispatch(setIsFetchingMobileWithdraws(true));

    axios
      .get(
        APP.API_ADVERTISER_URL +
          `/admin/wallets/payout/${payment_wallet[0].id}/withdraws/mobile-withdraws`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingMobileWithdraws(false));

        dispatch(setMobileWithdraws(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingMobileWithdraws(false));
        console.log(error);
      });
  }
};

export const fetchBankWithdraws = () => (dispatch, getState) => {
  const { token } = getState().user;
  const { payment_wallet } = getState().fetchWallet;
  if (payment_wallet.length > 0) {
    dispatch(setIsFetchingBankWithdraws(true));
    axios
      .get(
        APP.API_ADVERTISER_URL +
          `/admin/wallets/payout/${payment_wallet[0].id}/withdraws/bank-withdraws`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingBankWithdraws(false));
        dispatch(setBankWithdraws(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingBankWithdraws(false));
        console.log(error);
      });
  }
};

export const fetchCreditDeposits = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  const { credit_wallet } = getState().fetchWallet;
  if (credit_wallet.length > 0) {
    dispatch(setIsFetchingCreditDeposits(true));

    axios
      .get(
        APP.API_ADVERTISER_URL +
          `/advertiser/profile/${profileId}/wallets/${credit_wallet[0].id}/deposits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingCreditDeposits(false));

        dispatch(setCreditDeposits(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingCreditDeposits(false));
        console.log(error);
      });
  }
};

export const fetchCreditTransactions = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  const { credit_wallet } = getState().fetchWallet;
  if (credit_wallet.length > 0) {
    dispatch(setIsFetchingCreditTransactions(true));

    axios
      .get(
        APP.API_ADVERTISER_URL +
          `/advertiser/profile/${profileId}/wallets/${credit_wallet[0].id}/transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingCreditTransactions(false));

        dispatch(setCreditTransactions(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingCreditTransactions(false));
        console.log(error);
      });
  }
};

export const fetchCreditTransfers = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  const { credit_wallet } = getState().fetchWallet;
  if (credit_wallet.length > 0) {
    dispatch(setIsFetchingCreditTransfers(true));

    axios
      .get(
        APP.API_ADVERTISER_URL +
          `/advertiser/profile/${profileId}/wallets/${credit_wallet[0].id}/transfers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingCreditTransfers(false));

        dispatch(setCreditTransfers(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingCreditTransfers(false));
        console.log(error);
      });
  }
};

export const fetchCreditUsage = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  const { credit_wallet } = getState().fetchWallet;

  if (credit_wallet.length > 0) {
    axios
      .get(
        APP.API_ADVERTISER_URL +
          `/advertiser/profile/${profileId}/wallets/${credit_wallet[0].id}/usage`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setCreditUsage(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const fetchPaymentDeposits = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  const { payment_wallet } = getState().fetchWallet;
  if (payment_wallet.length > 0) {
    dispatch(setIsFetchingPaymentDeposits(true));

    axios
      .get(
        APP.API_ADVERTISER_URL +
          `/advertiser/profile/${profileId}/wallets/${payment_wallet[0].id}/deposits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingPaymentDeposits(false));

        dispatch(setPaymentDeposits(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingPaymentDeposits(false));
        console.log(error);
      });
  }
};

export const fetchPaymentTransactions = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  const { payment_wallet } = getState().fetchWallet;
  if (payment_wallet.length > 0) {
    dispatch(setIsFetchingPaymentTransactions(true));

    axios
      .get(
        APP.API_ADVERTISER_URL +
          `/advertiser/profile/${profileId}/wallets/${payment_wallet[0].id}/transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingPaymentTransactions(false));

        dispatch(setPaymentTransactions(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingPaymentTransactions(false));
        console.log(error);
      });
  }
};

export const fetchPaymentTransfers = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  const { payment_wallet } = getState().fetchWallet;
  if (payment_wallet.length > 0) {
    dispatch(setIsFetchingPaymentTransfers(true));

    axios
      .get(
        APP.API_ADVERTISER_URL +
          `/advertiser/profile/${profileId}/wallets/${payment_wallet[0].id}/transfers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingPaymentTransfers(false));

        dispatch(setPaymentTransfers(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingPaymentTransfers(false));
        console.log(error);
      });
  }
};

export const fetchMobileWalletAccount = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  dispatch(setIsFetchingMobileAccount(true));

  axios
    .get(
      APP.API_ADVERTISER_URL +
        `/advertiser/profile/${profileId}/payment-details/mobile-accounts`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      dispatch(setIsFetchingMobileAccount(false));

      dispatch(setAllMobileAccount(res.data.data));

      const primaryAccount = res.data.data.filter(
        (account) => account.is_default === 1
      );

      if (primaryAccount.length > 0) {
        dispatch(setDefaultAccountType(accountTypes.mobile));
        dispatch(setDefaultAccount(primaryAccount));
      }

      dispatch(
        setMobileAccount(
          res.data.data.filter((account) => account.is_default === 0)
        )
      );
    })
    .catch((error) => {
      dispatch(setIsFetchingMobileAccount(false));
      console.log(error);
    });
};

export const fetchBankWalletAccount = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  dispatch(setIsFetchingBankAccount(true));

  axios
    .get(
      APP.API_ADVERTISER_URL +
        `/advertiser/profile/${profileId}/payment-details/bank-accounts`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      dispatch(setIsFetchingBankAccount(false));

      dispatch(setAllBankAccount(res.data.data));

      const primaryAccount = res.data.data.filter(
        (account) => account.is_default === 1
      );

      if (primaryAccount.length > 0) {
        dispatch(setDefaultAccountType(accountTypes.bank));
        dispatch(setDefaultAccount(primaryAccount));
      }

      dispatch(
        setBankAccount(
          res.data.data.filter((account) => account.is_default === 0)
        )
      );
    })
    .catch((error) => {
      dispatch(setIsFetchingBankAccount(false));
      console.log(error);
    });
};

export const fetchWallets = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  dispatch(setIsFetchingWallets(true));

  axios
    .get(APP.API_ADVERTISER_URL + `/advertiser/profile/${profileId}/wallets`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingWallets(false));

      dispatch(
        setCreditWallet(
          res.data.data.filter((wallet) => wallet.type === 'credit')
        )
      );

      dispatch(
        setPaymentWallet(
          res.data.data.filter((wallet) => wallet.type === 'payout')
        )
      );
    })
    .catch((error) => {
      dispatch(setIsFetchingWallets(false));
      console.log(error);
    });
};
