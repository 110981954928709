import React, { Suspense, useState, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Test } from "./Test";
import Test2 from "./Test2";
// import Registration from './pages/registration/Registration';
// import AdvertiserRoutes from './Routes/AdvertiserRoutes';
import ProtectedRoutes from "./Routes/ProtectedRoutes/ProtectedRoutes";
// import PublicProfile from './pages/PublicProfile';
// import Auth from './components/auth/Auth';
import Skeleton from "./components/Skeleton";
import { APP } from "./utils/constant";
import Thumb from "./components/Thumb";
import { ToasterComponent } from "./components/Toast";
import Modal2 from "./components/modal/Modal2";

const Registration = lazy(() => import("./pages/registration/Registration"));
const AdvertiserRoutes = lazy(() => import("./Routes/AdvertiserRoutes"));
const PublicProfile = lazy(() => import("./pages/PublicProfile"));
const Auth = lazy(() => import("./components/auth/Auth"));
const QrView = lazy(() => import("./pages/ViewScanned"));

const VerifyIndividual = lazy(() =>
  import("./pages/settings/KYC/verification/VerifyIndividual")
);

const VerifyShareholder = lazy(() =>
  import("./pages/settings/KYC/verification/VerifyShareholder")
);

function App() {
  return (
    <Router>
      <ToasterComponent />
      <Suspense fallback={<Skeleton />}>
        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoutes redirectTo={APP.ACCOUNT_URL}>
                <AdvertiserRoutes />
              </ProtectedRoutes>
            }
          />

          <Route path="/auth/:token" element={<Auth />} />
          <Route path="/auth/:token/:profile/:id" element={<Auth />} />
          <Route path="/qr-code/c/:reference" element={<QrView />} />
          {/* <Route path="/test2" element={<Test2 />} /> */}
          <Route path="/test2" element={<Thumb />} />
          <Route path="/test" element={<Test />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes redirectTo={APP.ACCOUNT_URL}>
                <Registration />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/individual/kyc/verify/"
            element={<VerifyIndividual />}
          />

          <Route path="/company/kyc/verify/" element={<VerifyShareholder />} />

          <Route path="/:type/:username/" element={<PublicProfile />} />
          <Route path="/:type/:slug" element={<PublicProfile />} />
          <Route path="/test2" element={<Test2 />} />
          <Route path="/test" element={<Test />} />
          {/* <Route path="/suspense" element={<Skeleton />} /> */}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
