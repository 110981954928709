/* component author: TYPatrick*/
import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { BsCheckLg } from 'react-icons/bs';

export default function DateRangePicker({
  dateRange,
  highlightedDays,
  value,
  setHighlightedDays,
  setValue,
}) {
  console.log(highlightedDays, 'highlightedDays');
  function toggleHighlightedDays(day, month, date) {
    // const fromDate = dayjs(dateRange[0].from);
    // const toDate = dayjs(dateRange[0].to);
    // const daysArray = [];
    // console.log('clicked here');
    // // Loop through all days in the date range and add them to the daysArray
    // let currentDate = fromDate;
    // while (currentDate.isSameOrBefore(toDate, 'day')) {
    //   daysArray.push({
    //     d: currentDate.date(),
    //     m: currentDate.month(),
    //     date: currentDate.format('MMM DD YYYY'),
    //     time: highlightedDays.days[0].time, // Use the time from the first day of the range
    //   });
    //   currentDate = currentDate.add(1, 'day');
    // }

    // // Set the daysArray as the 'days' property of the highlightedDays object
    // setHighlightedDays((prevData) => {
    //   return {
    //     ...prevData,
    //     days: daysArray,
    //   };
    // });

    const index = highlightedDays.days.findIndex((highlightedDay) => {
      return highlightedDay.d === day && highlightedDay.m === month;
    });

    if (index === -1) {
      // Not present, so add it
      setHighlightedDays((prevData) => {
        const now = new Date();
        now.setTime(now.getTime() + 30 * 60 * 1000); // add 30 minutes
        const timeString = now.toLocaleTimeString('en-US', {
          timeZone: 'Africa/Kigali',
          hour12: false,
        });
        return {
          ...prevData,
          days: [
            ...prevData.days,
            { d: day, m: month, date: date, time: timeString.slice(0, 5) },
          ],
        };
      });
    } else {
      // Already present, so remove it
      const newDays = [...highlightedDays.days];
      newDays.splice(index, 1);
      setHighlightedDays((prevData) => {
        return { ...prevData, days: newDays };
      });
    }
  }

  return (
    <div className="w-fit h-fit">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          className="w-fit mt-4"
          orientation="portrait"
          openTo="day"
          value={value}
          minDate={dateRange[0].from ? dateRange[0].from : dayjs()}
          maxDate={dateRange[0].to ? dateRange[0].to : dayjs()}
          onChange={(newValue) => {
            const date = new Date(newValue);

            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            const formattedDate = `${month} ${day} ${year}`;

            setValue(newValue);
            toggleHighlightedDays(newValue.$D, newValue.$M, formattedDate);
          }}
          renderInput={(params) => <TextField {...params} />}
          renderDay={(day, _value, DayComponentProps) => {
            const isSelected = highlightedDays.days.some((highlightedDay) => {
              return (
                highlightedDay.d === day.date() &&
                highlightedDay.m === day.month()
              );
            });
            return (
              <Badge
                key={day.toString()}
                overlap="circular"
                badgeContent={isSelected ? <BsCheckLg /> : undefined}
              >
                <PickersDay {...DayComponentProps} />
              </Badge>
            );
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
