import { combineReducers } from "redux";
import user from "./user";
import profile from "./profile";
import { fetchUssd } from "./ussd";
import { fetchCampaign } from "./campaign";
import { fetchUtils } from "./utils";
import { fetchWallet } from "./wallet";
import { kyc } from "./kyc";
import qrCode from "./qrCod";
import blasts from "./blasts";

const rootReducer = combineReducers({
  user,
  profile,
  fetchUssd,
  fetchCampaign,
  fetchUtils,
  fetchWallet,
  qrCode,
  kyc,
  blasts,
});

export default rootReducer;
