import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ children, redirectTo }) => {
  const { token } = useSelector((state) => state.user);
  if (token) {
    return children;
  } else {
    return window.location.replace(
      redirectTo + `?redirect=${window.location.pathname.replace("/", "")}`
    );
  }
};

export default ProtectedRoutes;
