import axios from 'axios';
import { APP } from '../../utils/constant';
export const IS_FETCHING_MENU = 'IS_FETCHING_MENU';
export const FETCHED_MENU = 'FETCHED_MENU';
export const FETCHED_MENU_ITEMS = 'FETCHED_MENU_ITEMS';
export const IS_MENU_CONTENT_TYPE_FETCHING = 'IS_MENU_CONTENT_TYPE_FETCHING';
export const FETCHED_MENU_CONTENT_TYPE = 'FETCHED_MENU_CONTENT_TYPE';
export const IS_PROFILE_CONTENT_TYPE_FETCHING =
  'IS_PROFILE_CONTENT_TYPE_FETCHING';
export const FETCHED_PROFILE_CONTENT_TYPE = 'FETCHED_PROFILE_CONTENT_TYPE';

export const setIsFetchingMenu = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_MENU,
    payload: payload,
  });
};

export const setIsFetchingMenuContentType = (payload) => (dispatch) => {
  dispatch({
    type: IS_MENU_CONTENT_TYPE_FETCHING,
    payload: payload,
  });
};

export const setMenuContentType = (payload) => (dispatch) => {
  dispatch({
    type: FETCHED_MENU_CONTENT_TYPE,
    payload,
  });
};

export const setBeginMenu = (payload) => (dispatch) => {
  dispatch({
    type: FETCHED_MENU,
    payload: payload,
  });
};

export const setMenuItems = (payload) => (dispatch) => {
  dispatch({
    type: FETCHED_MENU_ITEMS,
    payload: payload,
  });
};

export const setIsFetchingProfileContentType = (payload) => (dispatch) => {
  dispatch({
    type: IS_PROFILE_CONTENT_TYPE_FETCHING,
    payload: payload,
  });
};

export const setProfileContentType = (payload) => (dispatch) => {
  dispatch({
    type: FETCHED_PROFILE_CONTENT_TYPE,
    payload,
  });
};

export const fetchMenuContentType = () => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingMenuContentType(true));
  axios
    .get(APP.API_ADVERTISER_URL + `/utils/user/menu-content-types`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingMenuContentType(false));
      dispatch(
        setMenuContentType(
          res.data.data.map((value) => {
            return {
              value: value.id,
              label: value.name,
            };
          })
        )
      );
    })
    .catch((error) => {
      dispatch(setIsFetchingMenuContentType(false));
      console.log(error);
    });
};

export const fetchProfileContentType = () => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingProfileContentType(true));
  axios
    .get(APP.API_ADVERTISER_URL + `/utils/user/profile-content-types`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingProfileContentType(false));
      dispatch(
        setProfileContentType(
          res.data.data.map((value) => {
            return {
              value: value.id,
              label: value.name,
            };
          })
        )
      );
    })
    .catch((error) => {
      dispatch(setIsFetchingProfileContentType(false));
      console.log(error, 'some random error');
    });
};

export const fetchMenu = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  dispatch(setIsFetchingMenu(true));

  axios
    .get(
      APP.API_ADVERTISER_URL + `/advertiser/profile/${profileId}/ussd/menus`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      dispatch(setIsFetchingMenu(false));
      dispatch(setBeginMenu(res.data.data));

      if (res.data.data.length > 0) {
        axios
          .get(
            APP.API_ADVERTISER_URL +
              `/advertiser/profile/${profileId}/ussd/menus/${res.data.data[0].id}/menu-items`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          )
          .then((res) => {
            dispatch(setMenuItems(res.data.data));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })
    .catch((error) => {
      dispatch(setIsFetchingMenu(false));
      console.log(error);
    });
};

export const triggerFetchMenu = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  const { fetched_menu } = getState().fetchUssd;
  axios
    .get(
      APP.API_ADVERTISER_URL +
        `/advertiser/profile/${profileId}/ussd/menus/${fetched_menu[0].id}/menu-items`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      dispatch(setMenuItems(res.data.data));
    })
    .catch((error) => {
      console.log(error);
    });
};
