import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

const CustomNextArrow = ({ onClick }) => {
  return (
    <button
      className="custom-next-arrow absolute top-[40%] md:top-[35%]"
      onClick={onClick}
      style={{
        fontSize: "24px",
        fontWeight: "bold",
        right: "-1rem",
      }}
    >
      <div className=" text-[#615e5e] rounded-full md:p-5 md:shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]">
        <MdOutlineArrowForwardIos size={30} />
      </div>
    </button>
  );
};

const CustomPrevArrow = ({ onClick }) => {
  return (
    <button
      className="custom-arrow custom-prev-arrow z-10 absolute top-[40%] md:top-[35%] "
      onClick={onClick}
      style={{
        fontSize: "24px",
        fontWeight: "bold",

        left: "-1rem",
      }}
    >
      <div className="rounded-full text-[#615e5e] md:p-5 md:shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]">
        <MdOutlineArrowBackIosNew size={30} />
      </div>
    </button>
  );
};

const Carausal = ({
  slidesToShow,
  slidesToScroll,
  Style,
  children,
  setCurrentSlide,
  autoplay,
  infinite,
  dots,
  currentSlide,
}) => {
  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  const settings = {
    dots: dots ? dots : false,
    infinite: infinite ? infinite : false,
    autoplay: autoplay ? autoplay : false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    afterChange: handleSlideChange,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings} className={`${Style}`}>
        {currentSlide ? children[currentSlide] : children}
      </Slider>
    </div>
  );
};

export default Carausal;
