import axios from 'axios';
import { APP } from '../../utils/constant';
export const IS_FETCHING_CAMPAIGN_TYPES = 'IS_FETCHING_CAMPAIGN_TYPES';
export const FETCHED_CAMPAIGN_TYPES = 'FETCHED_CAMPAIGN_TYPES';

export const setIsFetchingCampaignTypes = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_CAMPAIGN_TYPES,
    payload,
  });
};

export const setCampaignTypes = (payload) => (dispatch) => {
  dispatch({
    type: FETCHED_CAMPAIGN_TYPES,
    payload,
  });
};

export const fetchCampaignTypes = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  dispatch(setIsFetchingCampaignTypes(true));

  axios
    .get(APP.API_ADVERTISER_URL + `/utils/user/campaign/types`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingCampaignTypes(false));
      const responseData = res.data.data.map((campaign) => ({
        label: campaign.name,
        value: campaign.id,
      }));

      dispatch(setCampaignTypes(responseData));
    })
    .catch((error) => {
      dispatch(setIsFetchingCampaignTypes(false));
      console.log(error);
    });
};
