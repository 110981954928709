import axios from 'axios';
import { APP } from '../../utils/constant';
export const IS_FETCHING_KYC = 'IS_FETCHING_KYC';
export const FETCHED_KYC = 'FETCHED_KYC';

export const setIsFetchingKyc = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_KYC,
    payload,
  });
};

export const setFetchedKyc = (payload) => (dispatch) => {
  dispatch({
    type: FETCHED_KYC,
    payload,
  });
};

export const fetchKyc = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;
  dispatch(setIsFetchingKyc(true));
  axios
    .get(APP.API_MAIN_URL + `/advertiser/profile/${profileId}/kyc`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingKyc(false));
      dispatch(setFetchedKyc(res.data.data));
    })
    .catch((error) => {
      dispatch(setIsFetchingKyc(false));
      console.log(error);
    });
};
