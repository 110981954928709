import {
  IS_FETCHING_MENU,
  FETCHED_MENU,
  FETCHED_MENU_ITEMS,
  IS_MENU_CONTENT_TYPE_FETCHING,
  FETCHED_MENU_CONTENT_TYPE,
  IS_PROFILE_CONTENT_TYPE_FETCHING,
  FETCHED_PROFILE_CONTENT_TYPE,
} from '../actions/ussd';

const initialState = {
  is_menu_loading: false,
  is_menu_content_type_loading: false,
  is_profile_content_type_fetching: false,
  fetched_menu: [],
  menu_items: [],
  menu_content_type: [],
  profile_content_type: [],
};

export const fetchUssd = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_MENU:
      return {
        ...state,
        is_menu_loading: action.payload,
      };

    case FETCHED_MENU:
      return {
        ...state,
        fetched_menu: action.payload,
      };

    case FETCHED_MENU_ITEMS:
      return {
        ...state,
        menu_items: action.payload,
      };

    case IS_MENU_CONTENT_TYPE_FETCHING:
      return {
        ...state,
        is_menu_content_type_loading: action.payload,
      };

    case FETCHED_MENU_CONTENT_TYPE:
      return {
        ...state,
        menu_content_type: action.payload,
      };

    case IS_PROFILE_CONTENT_TYPE_FETCHING:
      return {
        ...state,
        is_profile_content_type_fetching: action.payload,
      };
    case FETCHED_PROFILE_CONTENT_TYPE:
      return {
        ...state,
        profile_content_type: action.payload,
      };
    default:
      return state;
  }
};
