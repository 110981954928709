import {
  SET_QR_CODE,
  SET_IS_LOADING_QR_CODE,
  RESET_QR_CODE,
} from "../actions/qrCode";

const initialState = {
  qrCodes: [],
  isLoading: false,
};

const qrCode = (state = initialState, action) => {
  switch (action.type) {
    case SET_QR_CODE:
      return { ...state, qrCodes: action.payload };

    case SET_IS_LOADING_QR_CODE:
      return { ...state, isLoading: action.payload };

    case RESET_QR_CODE:
      return initialState;

    default:
      return state;
  }
};

export default qrCode;
