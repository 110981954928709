import {
  IS_FETCHING_MOBILE_ACCOUNT,
  IS_FETCHING_BANK_ACCOUNT,
  FETCH_MOBILE_ACCOUNT,
  FETCH_BANK_ACCOUNT,
  FETCH_ALL_MOBILE_ACCOUNT,
  FETCH_ALL_BANK_ACCOUNT,
  FETCH_DEFAULT_ACCOUNT,
  FETCH_DEFAULT_ACCOUNT_TYPE,
  IS_FETCHING_WALLETS,
  FETCH_CREDIT_WALLET,
  FETCH_PAYMENT_WALLET,
  IS_FETCHING_CREDIT_DEPOSITS,
  FETCH_CREDIT_DEPOSITS,
  IS_FETCHING_CREDIT_TRANSACTIONS,
  FETCH_CREDIT_TRANSACTIONS,
  IS_FETCHING_CREDIT_TRANSFERS,
  FETCH_CREDIT_TRANSFERS,
  FETCH_CREDIT_USAGE,
  IS_FETCHING_PAYMENT_DEPOSITS,
  FETCH_PAYMENT_DEPOSITS,
  IS_FETCHING_PAYMENT_TRANSACTIONS,
  FETCH_PAYMENT_TRANSACTIONS,
  IS_FETCHING_PAYMENT_TRANSFERS,
  FETCH_PAYMENT_TRANSFERS,
  IS_FETCHING_BANK_WITHDRAWS,
  IS_FETCHING_MOBILE_WITHDRAWS,
  FETCH_BANK_WITHDRAWS,
  FETCH_MOBILE_WITHDRAWS,
} from '../actions/wallet';

const initialState = {
  is_fetching_mobile_account: false,
  is_fetching_bank_account: false,
  is_fetching_wallets: false,
  is_fetching_credit_deposits: false,
  is_fetching_credit_transactions: false,
  is_fetching_credit_transfers: false,
  is_fetching_payment_deposits: false,
  is_fetching_payment_transactions: false,
  is_fetching_payment_transfers: false,
  is_fetching_bank_withdraws: false,
  is_fetching_mobile_withdraws: false,

  primary_account_type: '',
  credit_wallet: [],
  payment_wallet: [],
  primary_account: [],
  mobile_account: [],
  bank_account: [],
  all_mobile_account: [],
  all_bank_account: [],
  credit_deposits: [],
  credit_transactions: [],
  credit_transfers: [],
  credit_usage: null,
  payment_deposits: [],
  payment_transactions: [],
  payment_transfers: [],
  bank_withdraws: [],
  mobile_withdraws: [],
};

export const fetchWallet = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_MOBILE_ACCOUNT:
      return {
        ...state,
        is_fetching_mobile_account: action.payload,
      };

    case IS_FETCHING_BANK_ACCOUNT:
      return {
        ...state,
        is_fetching_bank_account: action.payload,
      };

    case FETCH_DEFAULT_ACCOUNT:
      return {
        ...state,
        primary_account: action.payload,
      };

    case FETCH_DEFAULT_ACCOUNT_TYPE:
      return {
        ...state,
        primary_account_type: action.payload,
      };

    case FETCH_MOBILE_ACCOUNT:
      return {
        ...state,
        mobile_account: action.payload,
      };

    case FETCH_BANK_ACCOUNT:
      return {
        ...state,
        bank_account: action.payload,
      };

    case FETCH_ALL_MOBILE_ACCOUNT:
      return {
        ...state,
        all_mobile_account: action.payload,
      };

    case FETCH_ALL_BANK_ACCOUNT:
      return {
        ...state,
        all_bank_account: action.payload,
      };

    case IS_FETCHING_WALLETS:
      return {
        ...state,
        is_fetching_wallets: action.payload,
      };
    case FETCH_CREDIT_WALLET:
      return {
        ...state,
        credit_wallet: action.payload,
      };
    case FETCH_PAYMENT_WALLET:
      return {
        ...state,
        payment_wallet: action.payload,
      };

    case IS_FETCHING_CREDIT_DEPOSITS:
      return {
        ...state,
        is_fetching_credit_deposits: action.payload,
      };

    case IS_FETCHING_CREDIT_TRANSACTIONS:
      return {
        ...state,
        is_fetching_credit_tansactions: action.payload,
      };

    case IS_FETCHING_CREDIT_TRANSFERS:
      return {
        ...state,
        is_fetching_credit_transfers: action.payload,
      };

    case FETCH_CREDIT_DEPOSITS:
      return {
        ...state,
        credit_deposits: action.payload,
      };

    case FETCH_CREDIT_TRANSACTIONS:
      return {
        ...state,
        credit_transactions: action.payload,
      };

    case FETCH_CREDIT_TRANSFERS:
      return {
        ...state,
        credit_transfers: action.payload,
      };

    case FETCH_CREDIT_USAGE:
      return {
        ...state,
        credit_usage: action.payload,
      };

    case IS_FETCHING_PAYMENT_DEPOSITS:
      return {
        ...state,
        is_fetching_payment_deposits: action.payload,
      };

    case IS_FETCHING_PAYMENT_TRANSACTIONS:
      return {
        ...state,
        is_fetching_payment_tansactions: action.payload,
      };

    case IS_FETCHING_PAYMENT_TRANSFERS:
      return {
        ...state,
        is_fetching_payment_transfers: action.payload,
      };

    case IS_FETCHING_BANK_WITHDRAWS:
      return {
        ...state,
        is_fetching_bank_withdraws: action.payload,
      };

    case IS_FETCHING_MOBILE_WITHDRAWS:
      return {
        ...state,
        is_fetching_mobile_withdraws: action.payload,
      };

    case FETCH_PAYMENT_DEPOSITS:
      return {
        ...state,
        payment_deposits: action.payload,
      };

    case FETCH_PAYMENT_TRANSACTIONS:
      return {
        ...state,
        payment_transactions: action.payload,
      };

    case FETCH_PAYMENT_TRANSFERS:
      return {
        ...state,
        payment_transfers: action.payload,
      };

    case FETCH_MOBILE_WITHDRAWS:
      return {
        ...state,
        mobile_withdraws: action.payload,
      };

    case FETCH_BANK_WITHDRAWS:
      return {
        ...state,
        bank_withdraws: action.payload,
      };

    default:
      return state;
  }
};
