import { IS_FETCHING_KYC, FETCHED_KYC } from '../actions/kyc';
const initialState = {
  is_fetching_kyc: false,
  fetched_kyc: {},
};

export const kyc = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_KYC:
      return {
        ...state,
        is_fetching_kyc: action.payload,
      };

    case FETCHED_KYC:
      return {
        ...state,
        fetched_kyc: action.payload,
      };

    default:
      return state;
  }
};
