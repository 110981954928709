import axios from "axios";
import { APP, config } from "../../utils/constant";

export const SET_SMS_BLAST = "SET_SMS_BLAST";

export const SET_EMAIL_BLAST = "SET_EMAIL_BLAST";

export const SET_IS_LOADING_BLAST = "SET_IS_LOADING_BLAST";

export const RESET_BLAST = "RESET_BLAST";

export const setBlasts = (blasts) => (dispatch) => {
  dispatch({
    type: SET_SMS_BLAST,
    payload: blasts,
  });
};

export const setEmailBlasts = (blasts) => (dispatch) => {
  dispatch({
    type: SET_EMAIL_BLAST,
    payload: blasts,
  });
};

export const setIsLoadingBlasts = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_BLAST,
    payload: trueOrFalse,
  });
};

export const resetBlasts = () => ({ type: RESET_BLAST });

export const getBlasts = () => (dispatch, getState) => {
  const { token, profileId } = getState().user;

  dispatch(setIsLoadingBlasts(true));

  axios
    .get(
      APP.API_ADVERTISER_URL + `/advertiser/profile/${profileId}/blast`,
      config(token)
    )
    .then((res) => {
      const data = res.data.data;

      const sortedData = data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      const newData = sortedData.filter(
        (item) => item.blast_type === "sms" && item.blast_payment !== null
      );

      const newEmailData = sortedData.filter(
        (item) => item.blast_type === "email" && item.blast_payment !== null
      );

      newData.unshift({ id: 0, title: "All Blasts" });
      newEmailData.unshift({ id: 0, title: "All Blasts" });

      dispatch(setBlasts(newData));
      dispatch(setEmailBlasts(newEmailData));
      dispatch(setIsLoadingBlasts(false));
    })
    .catch((error) => {
      console.log(error);
      dispatch(setIsLoadingBlasts(false));
      // error.response.status === 403 &&
      //   (window.location.href = 'https://account.konvey.rw/');
    });
};
