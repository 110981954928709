import React, { useState } from 'react';
import Button from './components/Button';
import MultiSelect from './components/MultiSelect';
import Select from './components/Select';
import Input from './components/Input';
import DateRangePicker from './components/DateRangePicker';
import { useForm } from 'react-hook-form';
import Drag from './components/Drag';
import Carausal from './components/Carausal';
import { Card } from './components/Card';
import Collapsible from './components/Collapsible';
const Test2 = () => {
  const [loading, setLoading] = useState(false);
  // let test;
  const handleButton = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const [buttonLoading, setButtonLoading] = useState(false);
  const spin = () => {
    setButtonLoading(true);
    setTimeout(() => {
      setButtonLoading(false);
    }, 2000);
  };

  const options = [
    { value: 'option1', label: 'Konvey Direct' },
    { value: 'option2', label: 'MTN-Rwanda' },
    { value: 'option3', label: 'Airtel-Rwanda' },
    { value: 'option4', label: 'RBA' },
    { value: 'option5', label: 'TV1 & R1' },
    { value: 'option6', label: 'Aflink' },
    { value: 'option7', label: 'BTN' },
    { value: 'option8', label: 'Rwanda Foam' },
    { value: 'option9', label: 'Prime TV' },
    { value: 'option10', label: 'TV10 & R10' },
  ];
  const dateRange = [{ from: new Date(), to: '2023-04-04' }];
  const onSubmit = (data) => {
    console.log(data);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  return (
    <div className="h-full w-full bg-white pt-8">
      <p className="text-center font-bold text-2xl font-nunito ">
        Components testing{' '}
      </p>
      <div className="my-8">
        <Carausal slidesToShow={3} slidesToScroll={2} Style={'w-3/4 mx-auto'}>
          <div className="item">
            <Card Style={'w-full bg-white'}>
              <div className="flex flex-row">
                <div className="w-1/3 bg-black">
                  <img
                    src="https://www.w3schools.com/images/lamp.jpg"
                    alt="flash"
                    className="w-full h-full"
                  />
                </div>
                <div className="w-3/4">
                  <h1 className="text-lg font-bold">Flash</h1>
                  <p>he is James</p>
                </div>
              </div>
            </Card>
          </div>
          <div className="item">
            <Card Style={'w-full bg-white'}>
              <div className="flex flex-row">
                <div className="w-1/3 bg-black">
                  <img
                    src="https://www.w3schools.com/images/lamp.jpg"
                    alt="flash"
                    className="w-full h-full"
                  />
                </div>
                <div className="w-3/4">
                  <h1 className="text-lg font-bold">Flash</h1>
                  <p>he is James</p>
                </div>
              </div>
            </Card>
          </div>
          <div className="item">
            <Card Style={'w-full bg-white'}>
              <div className="flex flex-row">
                <div className="w-1/3 bg-black">
                  <img
                    src="https://www.w3schools.com/images/lamp.jpg"
                    alt="flash"
                    className="w-full h-full"
                  />
                </div>
                <div className="w-3/4">
                  <h1 className="text-lg font-bold">Flash</h1>
                  <p>he is James</p>
                </div>
              </div>
            </Card>
          </div>
        </Carausal>
      </div>
      <div className=" p-4 bg-white shadow-xl w-[800px] mb-8 mx-auto ">
        <Button
          content="Loading"
          loading={loading}
          onClick={handleButton}
          btnColor="primary"
          Style={'text-white w-[100px] h-[40px] mr-4 ml-0'}
        />

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <Input
            key="01"
            labelText={'Name'}
            labelFor={'name'}
            id="01"
            name={'name'}
            type={'text'}
            isRequired={true}
            placeholder={'Name'}
            customClass="w-1/2"
            handleChange={(e) => {
              console.log('ha', e.target.value);
            }}
            register={register}
            errors={errors}
          />{' '}
          <Select
            className="w-1/2 my-4"
            isLoading={false}
            placeholder={'Select channel'}
            defaultValue={options}
            noRegister={{
              onChange: (e) => {
                console.log('just changed');
              },
            }}
            options={options}
          />
          <Button
            type="submit"
            content="Submit"
            loading={false}
            btnColor="primary"
            Style={'text-white w-[100px] h-[40px] mt-2 ml-0'}
          />
        </form>

        <MultiSelect
          className="w-1/2 mt-4"
          isLoading={false}
          placeholder={'Select channel'}
          defaultValue={options}
          register={register}
          noRegister={{
            onChange: (e) => {
              console.log('just changed');
            },
          }}
          options={options}
        />
        <DateRangePicker dateRange={dateRange} />
        <div className="flex flex-col mt-6">
          <Drag
            style="w-max h-max"
            flexStyle="flex flex-col space-y-6"
            uploadLimit={1}
            label={'Upload something ...'}
          />
        </div>

        <Collapsible
          heading={<h1 className="font-bold text-xl">title</h1>}
          body={<h1>Body</h1>}
        />
      </div>
    </div>
  );
};

export default Test2;
