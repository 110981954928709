import {
  IS_FETCHING_CAMPAIGN_TYPES,
  FETCHED_CAMPAIGN_TYPES,
} from '../actions/campaign';

const initialState = {
  is_campaign_types_loading: false,
  campaign_types: [],
};

export const fetchCampaign = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_CAMPAIGN_TYPES:
      return {
        ...state,
        is_campaign_types_loading: action.payload,
      };

    case FETCHED_CAMPAIGN_TYPES:
      return {
        ...state,
        campaign_types: action.payload,
      };
    default:
      return state;
  }
};
