import React, { useState, useEffect } from 'react';
import { BiChevronRight } from 'react-icons/bi';

function Collapsible({
  heading,
  body,
  key,
  Style,
  index,
  expandedIndex,
  setExpandedIndex,
  setExpandedChannel,
  setError,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (index === 0) {
      setIsExpanded(true);
    }
  }, []);

  // console.log(heading.props.children,'heading')
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    setExpandedIndex(index);
    setExpandedChannel(heading.props.children);
  };

  return (
    <div
      className={`shadow-md rounded-lg border border-black/10 ${Style}`}
      key={key}
    >
      <div
        className="flex items-center justify-between p-3 cursor-pointer  transition-all duration-300 ease-in-out"
        onClick={handleToggle}
      >
        <div className="flex flex-row gap-4 items-center">
          {heading}
          {setError ? (
            <h1 className="text-error font-semibold text-sm">
              Please fill out required fields in this form, then continue.
            </h1>
          ) : null}
        </div>

        <BiChevronRight
          className={`transform duration-200 ${
            isExpanded && index == expandedIndex ? 'rotate-90' : 'rotate-0'
          }`}
          size={40}
        />
      </div>

      <div
        className={`p-3 ${
          isExpanded && index == expandedIndex ? '' : 'hidden'
        }`}
      >
        {body}
      </div>
    </div>
  );
}

export default Collapsible;
