import axios from 'axios';
import { APP } from '../../utils/constant';
export const SET_ADD_BLAST_EMAIL = 'SET_ADD_BLAST_EMAIL';
export const SET_ADD_BLAST_SMS = 'SET_ADD_BLAST_SMS';
export const SET_OPEN_TOP_UP = 'SET_OPEN_TOP_UP';
export const RESET_MODAL = 'RESET_MODAL';
export const IS_FETCHING_BANKS = 'IS_FETCHING_BANKS';
export const FETCH_BANKS = 'FETCH_BANKS';
export const IS_FETCHING_COUNTRIES = 'IS_FETCHING_COUNTRIES';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const SET_OPEN_IMPORT_AUDIENCE_MODAL = 'SET_OPEN_IMPORT_AUDIENCE_MODAL';
export const SET_CLOSE_IMPORT_AUDIENCE_MODAL =
  'SET_CLOSE_IMPORT_AUDIENCE_MODAL';

export const SET_AUDIENCE_ID = 'SET_AUDIENCE_ID';
export const SET_AUDIENCE_STEP_TWO = 'SET_AUDIENCE_STEP_TWO';
export const SET_REMOVE_AUDIENCE_STEP_TWO = 'SET_REMOVE_AUDIENCE_STEP_TWO';

export const setAddBlastSms = (modal) => (dispatch) => {
  dispatch({
    type: SET_ADD_BLAST_SMS,
    payload: modal,
  });
};
export const setAddBlastEmail = (modal) => (dispatch) => {
  dispatch({
    type: SET_ADD_BLAST_EMAIL,
    payload: modal,
  });
};

export const setTopUp = (modal) => (dispatch) => {
  dispatch({
    type: SET_OPEN_TOP_UP,
    payload: modal,
  });
};

export const resetModal = () => ({ type: RESET_MODAL });

export const setIsFetchingBanks = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_BANKS,
    payload: payload,
  });
};

export const setFetchedBanks = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_BANKS,
    payload: payload,
  });
};

export const setIsFetchingCountries = (payload) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_COUNTRIES,
    payload: payload,
  });
};

export const setFetchedCountries = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_COUNTRIES,
    payload: payload,
  });
};

export const openImportAudienceModal = () => (dispatch) => {
  dispatch({
    type: SET_OPEN_IMPORT_AUDIENCE_MODAL,
    payload: true,
  });
};

export const closeImportAudienceModal = () => (dispatch) => {
  dispatch({
    type: SET_CLOSE_IMPORT_AUDIENCE_MODAL,
    payload: false,
  });
};

export const setImportAudienceId = (payload) => (dispatch) => {
  dispatch({
    type: SET_AUDIENCE_ID,
    payload,
  });
};

export const setAudienceStepTwo = () => (dispatch) => {
  dispatch({
    type: SET_AUDIENCE_STEP_TWO,
    payload: true,
  });
};

export const setRemoveAudienceStepTwo = () => (dispatch) => {
  dispatch({
    type: SET_REMOVE_AUDIENCE_STEP_TWO,
    payload: false,
  });
};

export const fetchBanks = () => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingBanks(true));
  axios
    .get(APP.API_ADVERTISER_URL + `/utils/user/banks?accounts=true`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingBanks(false));
      dispatch(
        setFetchedBanks(
          res.data.data.map((value) => {
            return {
              value: value.id,
              label: value.name,
              accounts: value.bank_accounts ? value.bank_accounts : [],
            };
          })
        )
      );
    })
    .catch((error) => {
      dispatch(setIsFetchingBanks(false));
      console.log(error);
    });
};

export const fetchCountries = () => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingCountries(true));
  axios
    .get(APP.API_MAIN_URL + `/utils/location/countries`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingCountries(false));
      dispatch(
        setFetchedCountries(
          res.data.data.map((value) => {
            return {
              value: value.id,
              label: value.name,
            };
          })
        )
      );
    })
    .catch((error) => {
      dispatch(setIsFetchingCountries(false));
      console.log(error);
    });
};
